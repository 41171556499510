@tailwind base;
@tailwind components;
@tailwind utilities;


/* Prevent horizontal scroll and add smooth scrolling */
html, body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  scroll-behavior: smooth;
}


@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* Custom styles for Slick carousel */
.slick-prev,
.slick-next {
    z-index: 10;
    width: 40px;
    height: 40px;
}

@media (max-width: 768px) {
    .slick-prev,
    .slick-next {
        display: none !important;
    }
}

.slick-prev {
    left: 25px;
}

.slick-next {
    right: 25px;
}

.slick-prev:before,
.slick-next:before {
    font-size: 40px;
    opacity: 0.75;
}

.slick-dots {
    bottom: -40px;
}

/* Galaxy animation */
@keyframes galaxyShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.galaxy-text {
  background-size: 300% 300%;
  background-image: linear-gradient(
    45deg,
    theme('colors.galaxy.purple'),
    theme('colors.galaxy.blue'),
    theme('colors.galaxy.pink'),
    theme('colors.galaxy.green'),
    theme('colors.galaxy.purple')
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: galaxyShift 8s ease infinite;
}

.galaxy-text:hover {
  animation: galaxyShift 4s ease infinite;
  filter: brightness(1.2) contrast(1.1);
  text-shadow: 0 0 8px rgba(176, 141, 248, 0.5);
}

.galaxy-button {
  position: relative;
  isolation: isolate;
  transition: all 0.3s ease;
}

.galaxy-button::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    45deg,
    theme('colors.galaxy.purple'),
    theme('colors.galaxy.blue'),
    theme('colors.galaxy.pink'),
    theme('colors.galaxy.green'),
    theme('colors.galaxy.purple')
  );
  background-size: 300% 300%;
  border-radius: 9999px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.galaxy-button:hover::before {
  opacity: 1;
  animation: galaxyShift 4s ease infinite;
}


/* Typing animation styles */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.typing-cursor::after {
  content: '|';
  margin-left: 2px;
  animation: blink 0.7s infinite;
}

.typing-cursor.hide-cursor::after {
  opacity: 0;
}

/* Make dots use accent color */
.carousel-container .slick-dots li button:before {
    color: #B08DF8 !important;
    opacity: 0.5;
    font-size: 8px;
}

.carousel-container .slick-dots li.slick-active button:before {
    color: #B08DF8 !important;
    opacity: 1;
}

/* Optimize carousel transitions and touch handling */
.carousel-container .slick-slider {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.carousel-container .slick-slide {
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    will-change: transform;
}

.carousel-container .slick-track {
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform;
}

/* Prevent iOS glitches */
.carousel-container {
    -webkit-transform: translateZ(0);
    -webkit-transform-style: preserve-3d;
}
